<template>
  <div class="orders-page">
    <a-row type="flex" justify="space-between">
      <a-col :lg="10" :md="10" :xs="24">
        <h2 class="list-title">
          <font-awesome-icon
            class="anticon list-icon"
            :icon="['fas', 'arrow-right-arrow-left']"
          />
          <span>{{ labels.title }}</span>
        </h2>
        <p class="list-subtitle">{{ labels.subtitle }}</p>
      </a-col>
      <a-col :lg="14" :md="14" :xs="24">
        <a-row
          type="flex"
          justify="end"
          :gutter="[8, 8]"
          :style="{
            alignItems: 'flex-end',
            marginTop: '25px',
            marginBottom: '10px',
            textAlign: 'right',
          }"
        >
          <a-col
            :xs="filtered ? 14 : 16"
            :sm="filtered ? 19 : 20"
            :md="filtered ? 16 : 20"
          >
            <a-input-search
              :placeholder="labels.placeholderSearch"
              style="width: 100%"
              @search="handleSearch"
              allowClear
            />
          </a-col>
          <a-col
            :xs="filtered ? 10 : 8"
            :sm="filtered ? 5 : 4"
            :md="filtered ? 8 : 4"
            :style="{ textAlign: 'right' }"
          >
            <a-button-group>
              <a-button type="primary" icon="plus" @click="openFilter">
                {{ labels.filters.filters }}
              </a-button>
              <a-button
                v-if="filtered"
                type="danger"
                icon="close"
                @click="handleResetFilter"
              />
            </a-button-group>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <StandardTable
          ref="standardTable"
          :fetchService="fetchOrders"
          :columns="columns"
          :locale="locale"
          :handleShowTotal="handleShowTotal"
          v-bind="!isMobile && { scroll: { x: true } }"
          :paginationType="isMobile ? 'arrows' : 'numbers'"
          :customRow="handleCustomRow"
        />
      </a-col>
    </a-row>
    <FilterForm
      ref="filterForm"
      :labels="labels.filters"
      :visible="showFilter"
      :onClose="closeFilter"
      :onSearch="onFilter"
      :filters="filters"
    />
  </div>
</template>
<script>
import _ from "lodash";
import labels from "@/utils/labels";
import StandardTable from "../../../components/core/table/StandardTable.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import accounting from "accounting";
import FilterForm from "./FilterForm.vue";
import { DATE_FORMAT, adminPageTitle } from "../../../utils/utils";
// import { hasRoles, roles } from "../../../utils/utils";
const initialState = {
  dateRange: [],
  user: [],
  code: "",
  status: [],
};
export default {
  components: { StandardTable, FilterForm },
  name: "OrderList",
  metaInfo: {
    title: adminPageTitle(labels.orders.title),
  },
  filters: {
    ucwords(value) {
      if (!value) return "";
      value = value.toString();
      return value
        .split(" ")
        .map((item) => _.capitalize(item))
        .join(" ");
    },
  },
  data() {
    return {
      labels: labels.orders,
      search: "",
      showFilter: false,
      filters: _.cloneDeep(initialState),
      activeRow: null,
    };
  },
  methods: {
    ...mapActions("orders", ["fetchOrders"]),
    handleReloadTable() {
      let filtersTable = this.$refs.standardTable.filters;
      let result = { ...filtersTable };
      this.$refs.standardTable.handleFilters(result);
    },
    handleSearch(search) {
      this.$refs.standardTable.handleSearch(search);
    },
    handleShowTotal(total, range) {
      return `${range[0]}-${range[1]} de ${total}`;
    },
    openFilter() {
      this.showFilter = true;
    },
    closeFilter() {
      this.showFilter = false;
    },
    onFilter() {
      let filtersTable = this.$refs.standardTable.filters;
      let result = { ...filtersTable, ...this.filters };
      Object.keys(result).forEach((key) => {
        switch (key) {
          case "cancelled":
            if (!_.isEmpty(result[key]))
              result[key] = result[key] === "cancelled";
            break;
          case "type":
            if (!_.isEmpty(result[key])) result[key] = result[key].id;
            break;
          case "origin":
            if (!_.isEmpty(result[key])) result[key] = result[key].id;
            break;
          case "user":
            if (!_.isEmpty(result[key])) result[key] = result[key].id;
            break;
          case "dateRange":
            if (!_.isEmpty(result[key]))
              result[key] = result[key].map(
                (item) => item && item.format(this.serverDateFormat)
              );
            break;
        }
      });
      this.$refs.standardTable.handleFilters(result);
      this.closeFilter();
    },
    handleResetFilter() {
      this.filters = _.cloneDeep(initialState);
      this.onFilter();
      this.closeFilter();
    },
    statusColor(status = "success") {
      switch (status) {
        case "success":
        case "paid":
          return "#28a745";
        case "pending":
          return "#ffc107";
        case "cancelled":
        case "rejected":
          return "#f50";
        case "processed":
          return "#108ee9";
        default:
          return "";
      }
    },
    statusText(status = this.labels.table.columns.status.success) {
      return this.labels.table.columns?.status[status] ?? " - ";
    },
    handleCustomRow(_, index) {
      return {
        on: {
          click: () => {
            this.activeRow = this.activeRow === index ? null : index;
          },
        },
      };
    },
  },
  computed: {
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    ...mapState("layout", ["screenWidth"]),
    isMobile() {
      return this.screenWidth < 600;
    },
    locale() {
      return {
        emptyText: this.labels.table.emptyText,
      };
    },
    columns() {
      return [
        {
          title: this.labels.table.columns.date,
          dataIndex: "date",
          key: "date",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return this.$moment(text).format(DATE_FORMAT.MOMENT_DATE_TIME);
          },
        },
        {
          title: this.labels.table.columns.code,
          dataIndex: "code",
          key: "code",
          sorter: true,
          hidden: this.isMobile,
        },
        {
          title: this.labels.table.columns.user,
          dataIndex: "user",
          key: "user",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text, record) => {
            const fullname = `${text?.firstname || ""} ${
              text?.lastname || ""
            }`.trim();
            return record?.external && record?.buyer_name
              ? `${record.buyer_name} (${fullname})`.trim()
              : fullname;
          },
        },
        {
          title: this.labels.table.columns.totalAmount,
          dataIndex: "total_amount",
          key: "total_amount",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return accounting.formatNumber(text || 0, 2, ",", ".");
          },
        },
        {
          title: this.labels.table.columns.wonTotalAmount,
          dataIndex: "won_total_amount",
          key: "won_total_amount",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return accounting.formatNumber(text || 0, 2, ",", ".");
          },
        },
        {
          title: this.labels.table.columns.status.title,
          dataIndex: "status",
          key: "status",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return (
              <a-tag color={text !== "cancelled" ? "green" : "red"}>
                {text === "processed"
                  ? this.labels.table.columns.status.processed
                  : this.labels.table.columns.status.cancelled}
              </a-tag>
            );
          },
        },
        {
          title: this.labels.table.columns.action.title,
          dataIndex: "action",
          key: "action",
          width: "120px",
          hidden: this.isMobile,
          customRender: (_, record) => {
            return (
              <div class="action-buttons">
                <router-link
                  to={{
                    name: "admin.orders.view",
                    params: { id: record.id },
                  }}
                  title={this.labels.table.columns.action.view}
                >
                  <a-button type="link" icon="eye" class="action-button-view" />
                </router-link>
              </div>
            );
          },
        },
        {
          title: this.labels.table.columns.tickets,
          dataIndex: "tickets",
          key: "tickets",
          sorter: false,
          hidden: !this.isMobile,
          customRender: (_, record, index) => {
            // Card item design
            const color = this.statusColor(record.status?.toLowerCase());
            return (
              <div class="list-item">
                <div class="list-item-head">
                  <div
                    class="list-item-head-header"
                    style={{ borderColor: color }}
                  >
                    <span>
                      {this.$options.filters.ucwords(
                        this.$moment(record.date).format(
                          DATE_FORMAT.MOMENT_DAY_MONTH
                        )
                      )}
                    </span>
                    <small>
                      {this.$moment(record.date)
                        .format(DATE_FORMAT.MOMENT_TIME)
                        .toUpperCase()}
                    </small>
                  </div>
                  <div class="list-item-head-content">
                    <div class="list-item-head-content-number">
                      <span>{record.number}</span>
                    </div>
                    <div class="list-item-head-content-user">
                      <span>{record.user?.email}</span>
                    </div>
                    <div class="list-item-head-content-status">
                      <a-tag color={this.statusColor(record.status)}>
                        {this.statusText(record.status)}
                      </a-tag>
                    </div>
                  </div>
                  <div class="list-item-head-footer">
                    <div class="list-item-head-footer-amount">
                      <span>
                        {accounting.formatNumber(
                          record.total_amount,
                          2,
                          ",",
                          "."
                        )}
                      </span>
                    </div>
                    {record?.won_total_amount ? (
                      <div class="list-item-head-footer-amount">
                        <span class="success">
                          <font-awesome-icon
                            icon={["fas", "hand-holding-dollar"]}
                            class="anticon"
                          />
                          {accounting.formatNumber(
                            record.won_total_amount,
                            2,
                            ",",
                            "."
                          )}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
                {this.activeRow === index && (
                  <div class="list-item-actions">
                    <a-button-group>
                      <router-link
                        class="view-button ant-btn ant-btn-primary ant-btn-block"
                        to={{
                          name: "admin.orders.view",
                          params: { id: record.id },
                        }}
                        title={this.labels.table.columns.action.view}
                      >
                        {this.labels.table.columns.action.view}
                      </router-link>
                      {record?.is_cancellable ? (
                        <a-button
                          class="cancel-button ant-btn ant-btn-danger"
                          title={this.labels.table.columns.action.cancel}
                          onClick={() => this.handleConfirmCancellation(record)}
                          type="danger"
                        >
                          {this.labels.table.columns.action.cancel}
                        </a-button>
                      ) : null}
                    </a-button-group>
                  </div>
                )}
              </div>
            );
          },
        },
      ].filter((column) => !column.hidden);
    },
    filtered() {
      return !_.isEqual(this.filters, initialState);
    },
    serverDateFormat() {
      return DATE_FORMAT.SERVER_DATE;
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.orders-page {
  .list {
    &-title {
      margin-bottom: 10px;
      .list-icon {
        margin-right: 10px;
      }
    }
    &-subtitle {
      margin-bottom: 10px;
    }
  }
  .add-button {
    color: color(primary);
    border-color: color(primary);
    margin-left: 10px;
  }
  .action-buttons {
    display: flex;
    justify-content: space-between;
    .action-button-view {
      color: color(primary);
    }
    .action-button-cancel {
      color: color(danger);
    }
  }
  .list-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &-head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 6px;
      &-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        max-width: 25%;
        width: 25%;
        border-right: 4px solid color(border);
        .success {
          color: color(success);
        }
        .warning {
          color: color(warning);
        }
        .danger {
          color: color(danger);
        }
        span {
          font-size: 14px;
          font-weight: 600;
        }
        small {
          font-size: 12px;
          font-weight: 400;
          padding-right: 4px;
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2px;
        max-width: 40%;
        width: 40%;
        overflow: hidden;
        &-number {
          span {
            font-size: 16px;
            font-weight: 600;
            text-overflow: ellipsis;
          }
        }
        &-user {
          span {
            font-size: 12px;
            font-weight: 400;
            text-overflow: ellipsis;
          }
        }
        &-status {
          span {
            font-size: 12px;
            font-weight: 400;
            text-overflow: ellipsis;
          }
        }
      }
      &-footer {
        max-width: 35%;
        width: 35%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: right;
        gap: 2px;
        &-amount {
          width: 100%;
          span {
            width: 100%;
            font-size: 14px;
            font-weight: 600;
            &.success {
              color: color(success);
            }
            .anticon {
              margin-right: 6px;
            }
          }
          &:first-child {
            span {
              font-size: 16px;
            }
          }
        }
      }
    }
    &-actions {
      width: 100%;
      margin-top: 10px;
      .ant-btn-group {
        width: 100%;
        .more-info-button,
        .view-button,
        .cancel-button {
          width: 100%;
        }
      }
    }
  }
}
</style>
