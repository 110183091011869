<template>
  <a-drawer
    :title="labels.title"
    placement="right"
    :closable="true"
    :visible="visible"
    @close="onClose"
    :width="300"
  >
    <ValidationObserver ref="observer">
      <a-form
        :model="filters"
        :label-width="80"
        slot-scope="{ validate }"
        @submit.prevent="validate().then(onSearch)"
      >
        <a-row type="flex" justify="center" :gutter="[0, 0]">
          <a-col :span="24">
            <RangePicker
              v-model="filters.dateRange"
              :placeholder="[
                labels.form.dateRange.placeholder.startdate,
                labels.form.dateRange.placeholder.enddate,
              ]"
              :format="[dateFormat, dateFormat]"
              :formitem="{
                label: labels.form.dateRange.label,
              }"
              :allowClear="true"
            />
          </a-col>
          <a-col :span="24">
            <SelectPagination
              v-model="filters.user"
              :fetchService="fetchUsers"
              :placeholder="labels.form.user.placeholder"
              :labelKey="'fullname'"
              :valueKey="'id'"
              :label="labels.form.user.label"
              :disabledDefaultFetchOptions="false"
              :allowClear="true"
            />
          </a-col>
          <a-col :span="24">
            <InputValidation
              v-model="filters.code"
              :placeholder="labels.form.code.placeholder"
              :label="labels.form.code.label"
              :maxLength="13"
              v-mask="'OR###########'"
              :allowClear="true"
            />
          </a-col>
          <a-col :span="24">
            <SelectValidation
              v-model="filters.status"
              :placeholder="labels.form.status.placeholder"
              :label="labels.form.status.label"
              :dataSource="statuses"
              :allowClear="true"
            />
          </a-col>
        </a-row>
        <a-row type="flex" justify="center">
          <a-col>
            <a-button-group>
              <a-button type="danger" @click="onClose">{{
                labels.form.cancel
              }}</a-button>
              <a-button type="primary" @click="validate().then(onSearch)">{{
                labels.form.save
              }}</a-button>
            </a-button-group>
          </a-col>
        </a-row>
      </a-form>
    </ValidationObserver>
  </a-drawer>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import RangePicker from "../../../components/core/VeeValidateForm/RangePicker.vue";
import InputValidation from "../../../components/core/VeeValidateForm/Input.vue";
import SelectValidation from "../../../components/core/VeeValidateForm/Select.vue";
import SelectPagination from "../../../components/core/SelectPagination/index.vue";
import { mapActions } from "vuex";
import { DATE_FORMAT } from "../../../utils/utils";
export default {
  name: "FilterForm",
  components: {
    ValidationObserver,
    RangePicker,
    InputValidation,
    SelectValidation,
    SelectPagination,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onSearch: {
      type: Function,
      default: () => {},
    },
    labels: {
      type: Object,
      default: () => {},
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      statuses: [
        {
          label: this.labels.form.status.processed,
          value: "processed",
        },
        {
          label: this.labels.form.status.cancelled,
          value: "cancelled",
        },
      ],
    };
  },
  methods: {
    ...mapActions("orders", ["fetchOrders"]),
    ...mapActions("users", ["fetchUsers"]),
  },
  computed: {
    dateFormat() {
      return DATE_FORMAT.MOMENT_DATE;
    },
  },
};
</script>
